import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { Flex } from 'rebass/styled-components';
import Layout from '../components/Layout';
import { Section } from '../components/Section';
import Container from '../components/Container';
import PostList from '../components/PostList';
import PostTags from '../components/PostTags';

const Category = (props) => {
  const { data, pageContext } = props;
  const { edges: posts } = data.allWordpressPost;
  const { nodes: tags } = data.allWordpressTag;
  const { title: siteTitle } = data.site.siteMetadata;
  const { name: category, description } = pageContext;

  return (
    <Layout>
      <Helmet title={`${category} | ${siteTitle}`} />
      <Section>
        <Container>
          <h1>{category}</h1>
          <p>{description}</p>

          <Flex justifyContent="center" mb={4}>
            <PostTags tags={tags} largeTags />
          </Flex>

          <PostList posts={posts} />
        </Container>
      </Section>
    </Layout>
  );
};

export default Category;

export const pageQuery = graphql`
  query CategoryPage($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allWordpressPost(filter: { categories: { elemMatch: { slug: { eq: $slug } } } }) {
      totalCount
      edges {
        node {
          ...PostListFields
        }
      }
    }
    allWordpressTag {
      nodes {
        id
        slug
        name
        description
        count
      }
    }
  }
`;
